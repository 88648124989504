import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import WaterIcn from '@material-ui/icons/Opacity';
import PropTypes from 'prop-types';
import PDFIcon from '@material-ui/icons/PictureAsPdfOutlined';
import DownloadIcon from '@material-ui/icons/GetAppOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import SimpleCard from '../../components/SimpleCard';
import RecordDBatch from '../../containers/RecordDBatch';
import RecordD from '../../containers/RecordD';
import GenDialog from "../../components/GenDialog";
import Load from '../../components/Load';
import ReportByActivity from '../../components/ReportByActivity';
import { updateBatchStateRecord, updateStateRecord } from '../../libs/storage';
import BlockSelector from './blockSelector';
import ReportRecordPDF from '../ReportRecordPDF';
import ReportRecordCSV from '../ReportRecordCSV';
import DateRangeDialog from '../../components/DateRangeDialog';
import Can from "../../components/Can";



function HarvestIcon(){ return (<SvgIcon color='secondary' style={{fontSize:"x-large"}}  viewBox="0 0 24 24">
<path d="M23,4.1V2.3L21.2,2.1C21.1,2.1 20.5,2 19.5,2C15.4,2 12.4,3.2 10.7,5.3C9.4,4.5 7.6,4 5.5,4C4.5,4 3.8,4.1 3.8,4.1L1.9,4.4L2,6.1C2.1,9.1 3.6,14.8 8.8,14.8C8.9,14.8 8.9,14.8 9,14.8V18.2C5.2,18.7 2,20 2,20V22H22V20C22,20 18.8,18.7 15,18.2V15C21.3,14.9 23,7.8 23,4.1M12,18C11.7,18 11.3,18 11,18V12.4C11,12.4 10.8,9 8,9C8,9 9.5,9.8 9.9,12.7C9.5,12.8 9.1,12.8 8.8,12.8C4.2,12.8 4,6.1 4,6.1C4,6.1 4.6,6 5.5,6C7.4,6 10.5,6.4 11.4,9.1C11.9,4.6 17,4 19.5,4C20.4,4 21,4.1 21,4.1C21,4.1 21,13.1 14.7,13.1C14.5,13.1 14.2,13.1 14,13.1C14,11.1 16,8.1 16,8.1C13,9.1 13,13 13,13V18C12.7,18 12.3,18 12,18Z" />
></SvgIcon>)}

function BugIcon(){ return (<SvgIcon color='secondary' style={{fontSize:"x-large"}}  viewBox="0 0 24 24">
<path d="M20,8H17.19C16.74,7.2 16.12,6.5 15.37,6L17,4.41L15.59,3L13.42,5.17C12.96,5.06 12.5,5 12,5C11.5,5 11.05,5.06 10.59,5.17L8.41,3L7,4.41L8.62,6C7.87,6.5 7.26,7.21 6.81,8H4V10H6.09C6.03,10.33 6,10.66 6,11V12H4V14H6V15C6,15.34 6.03,15.67 6.09,16H4V18H6.81C8.47,20.87 12.14,21.84 15,20.18C15.91,19.66 16.67,18.9 17.19,18H20V16H17.91C17.97,15.67 18,15.34 18,15V14H20V12H18V11C18,10.66 17.97,10.33 17.91,10H20V8M16,15A4,4 0 0,1 12,19A4,4 0 0,1 8,15V11A4,4 0 0,1 12,7A4,4 0 0,1 16,11V15M14,10V12H10V10H14M10,14H14V16H10V14Z" />
></SvgIcon>)}

function ChemicalIcon(props){ return (<SvgIcon color='secondary' style={{fontSize:"x-large"}}  viewBox="0 0 24 24">
<path d="M3,3H21V5A2,2 0 0,0 19,7V19A2,2 0 0,1 17,21H7A2,2 0 0,1 5,19V7A2,2 0 0,0 3,5V3M7,5V7H12V8H7V9H10V10H7V11H10V12H7V13H12V14H7V15H10V16H7V19H17V5H7Z" />
></SvgIcon>)}

function FertiliserIcon(props){ return (<SvgIcon color='secondary' style={{fontSize:"x-large"}}  viewBox="0 0 24 24">
<path d="M10.5,3C8,3 6,5 6,7.5C6,8.11 6.13,8.71 6.37,9.27C5.5,10.12 5,11.28 5,12.5C5,15 7,17 9.5,17C10,17 10.5,16.89 11,16.72V21H13V15.77C13.5,15.91 14,16 14.5,16A5.5,5.5 0 0,0 20,10.5A5.5,5.5 0 0,0 14.5,5C14.41,5 14.33,5 14.24,5C13.41,3.76 12,3 10.5,3M10.5,5C11.82,5 12.91,6.03 13,7.35C13.46,7.12 14,7 14.5,7A3.5,3.5 0 0,1 18,10.5A3.5,3.5 0 0,1 14.5,14C13.54,14 12.63,13.61 11.96,12.91C11.76,14.12 10.72,15 9.5,15A2.5,2.5 0 0,1 7,12.5C7,11.12 7.8,10.54 9,9.79C8.2,8.76 8,8.16 8,7.5A2.5,2.5 0 0,1 10.5,5Z" />
></SvgIcon>)}

function AgronomistIcon() { return (<SvgIcon color='secondary' style={{fontSize:"x-large"}}  viewBox="0 0 24 24">
<path d="M19.5,12c0.93,0,1.78,0.28,2.5,0.76V8c0-1.1-0.9-2-2-2h-6.29l-1.06-1.06l1.41-1.41l-0.71-0.71L9.82,6.35l0.71,0.71 l1.41-1.41L13,6.71V9c0,1.1-0.9,2-2,2h-0.54c0.95,1.06,1.54,2.46,1.54,4c0,0.34-0.04,0.67-0.09,1h3.14 C15.3,13.75,17.19,12,19.5,12z"/><path d="M19.5,13c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5S21.43,13,19.5,13z M19.5,18 c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5s1.5,0.67,1.5,1.5S20.33,18,19.5,18z"/><path d="M4,9h5c0-1.1-0.9-2-2-2H4C3.45,7,3,7.45,3,8C3,8.55,3.45,9,4,9z"/><path d="M9.83,13.82l-0.18-0.47L10.58,13c-0.46-1.06-1.28-1.91-2.31-2.43l-0.4,0.89l-0.46-0.21l0.4-0.9C7.26,10.13,6.64,10,6,10 c-0.53,0-1.04,0.11-1.52,0.26l0.34,0.91l-0.47,0.18L4,10.42c-1.06,0.46-1.91,1.28-2.43,2.31l0.89,0.4l-0.21,0.46l-0.9-0.4 C1.13,13.74,1,14.36,1,15c0,0.53,0.11,1.04,0.26,1.52l0.91-0.34l0.18,0.47L1.42,17c0.46,1.06,1.28,1.91,2.31,2.43l0.4-0.89 l0.46,0.21l-0.4,0.9C4.74,19.87,5.36,20,6,20c0.53,0,1.04-0.11,1.52-0.26l-0.34-0.91l0.47-0.18L8,19.58 c1.06-0.46,1.91-1.28,2.43-2.31l-0.89-0.4l0.21-0.46l0.9,0.4C10.87,16.26,11,15.64,11,15c0-0.53-0.11-1.04-0.26-1.52L9.83,13.82z M7.15,17.77c-1.53,0.63-3.29-0.09-3.92-1.62c-0.63-1.53,0.09-3.29,1.62-3.92c1.53-0.63,3.29,0.09,3.92,1.62 C9.41,15.38,8.68,17.14,7.15,17.77z"/>
></SvgIcon>)}

function WaterIcon() { return (<WaterIcn color='secondary'/>) };

const styles = theme => ({
  root: {
    flexGrow: 1,
    // marginTop:'5px',
  },
  root2: {
    flexGrow: 1,
    marginTop:'5px',
  },
  iconContainer: {
    textAlign:'center',
    width:'60px',
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    marginBottom: theme.spacing(10),
    backgroundColor: 'inherit',
    // overflow: 'scroll',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  fab: {
    margin: theme.spacing(1),
  },
  fabFloat: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 80,
    left: 'auto',
    position: 'fixed',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  list :{
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  searchBar: {
    [theme.breakpoints.up('sm')]: {
      //display: 'none',
    },

  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  cardHeader: {
    paddingBottom: '6px',

  },
  cached: {
    fontSize: 15,
    color: theme.palette.secondary.main,
  },
  syncError: {
    fontSize: 15,
    color: 'red',
  },
  

  
});

const activities = [{type:'fertilisings', label:'Fertiliser', icon: FertiliserIcon, requiredFields: [{name:'product', min: 4, max: 200},{name:'operator', min: 1, max: 200},{name:'date', min: 1, max: 40}, {name:'volume', min: 1, max: 40},{name:'endTime', min: 1, max: 40}]},
      {type:'sprayings',label:'Plant Protection', icon: ChemicalIcon, requiredFields:[{name:'product', min: 1, max: 100},{name:'date', min: 1, max: 40}, {name:'reasons', min: 1, max: 40, type: 'array'}, {name:'chemrigs', min: 1, max: 200}, {name:'volume', min: 1, max: 40}, {name:'operator', min: 1, max: 200},{name:'weather', min: 1, max: 40}]},
      {type:'waterings',label:'Irrigation', icon: WaterIcon, requiredFields:[{name:'date', min: 1, max: 40}, {name:'duration', min: 1, max: 40},{name:'operator', min: 1, max: 200}, {name:'actualLiters', min: 0, max: 10000000000000, type: 'number'}] },
      {type:'harvestings', label: 'Harvest', icon: HarvestIcon, requiredFields:[{name:'harvestEquip', min: 1, max: 100, condition:{name:'totalHarvestOverride',value:false}}, {name:'harvestUnits', min: 1, max: 40, condition:{name:'totalHarvestOverride',value:false}},{name:'harvestTotal', min: 1, max: 40}, {name:'date', min: 1, max: 40}] },
      {type:'pestings',label:'Monitoring', icon: BugIcon, requiredFields:[{name:'date', min: 1, max: 40}, {name:'observations', min: 1, max: 40}]},
      {type:'activities',label:'Agronomic Activity', icon: AgronomistIcon, requiredFields: [{name:'activity', min: 1, max: 100},{name:'operator', min: 1, max: 200}, {name:'date', min: 1, max: 40}]}];

class Records extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blocks:[],
      openDialog: "",
      isNew: false,
      selectedBlock: null,
      selectedBlockSK:"",
      selectedRecordSK:"",
      selectedPlantingSK:"",
      selectedActivity: null,
      selectedRecord: null,
      showDetail:false,
      clean:true,
      blockSelector:false,
      selectedBlocks:[],
      allSelected:false,
      pdf:false,
      csv:false,
      activityPDF:null,
      activityCSV:null,
      dateRangeDialogOpen: false,
      reportStartDate: '',
      reportEndDate: new Date().toISOString().substr(0, 10),
      reportLoading:false,
      selectedCluster: '',
      selectedClusterGroup: '',
      reportType: '',
    };
  }

  componentDidMount() {
    const isMobile = !window.matchMedia('(min-width: 850px)').matches;
    if (!isMobile) {
      this.setState({ showDetail: true, clean: false });
    }
  }

  handleClusterGroupChange = (event) => {
    const { selectedClusterGroup } = this.state;
    if (event.target.value !== selectedClusterGroup) {
      this.setState({ selectedClusterGroup: event.target.value });
    }
  }


  handleClusterChange = (event) => {
    const { selectedCluster } = this.state;
    if (event.target.value !== selectedCluster) {
      this.setState({ selectedCluster: event.target.value });
    }
  }

   handleToggle = (planting, blockSK, label, blockOB,plantingOB) => {
    const newBlockList = [ ...this.state.selectedBlocks ];
    let allSelected = this.state.allSelected;
    const index = newBlockList && newBlockList.findIndex(block=>(block.planting === planting));
    if (index===-1){
        newBlockList.push({blockOB:blockOB, plantingOB:plantingOB, block:blockSK,planting:planting,label:label,record:{}})
    }
    else{
        newBlockList.splice(index,1);
        allSelected = false;
    }

    this.setState({ selectedBlocks: newBlockList, changed: true, allSelected:allSelected });
  }

   nameLookup = (record,key) => {
    let entity = record.find(e => e.sk === key);
    let name = '';
    if (entity) {
      name = entity.name
    }
    return name;
  
  }
   varietyLookup = (record,parentKey, childKey) => {
    let crop = record.find(crop => crop.sk === parentKey);
    let varietyName=''
    if (crop) {
      let variety = crop.varieties.find (v => v.sk===childKey)
      if (variety) {
        varietyName=variety.cultivar;
      }
    }
    return varietyName;
  }

  onSelectAll = (event) =>{
    let selectedBlocks = [...this.state.selectedBlocks];
    const { selectedCluster, allSelected, selectedClusterGroup } = this.state;
    let newAllSelected = allSelected;
    const crops = this.props.getFromGlobalState("crops");
    if (newAllSelected){
      newAllSelected = false;
      selectedBlocks = [];
    }
    else {
    let blocks = this.props.getFromGlobalState('blocks');
    if ( this.state.selectedActivity.type === "waterings"){
      blocks = blocks.filter((c) => c.irrigated === 'irrigated') 
    }
    for (var block of blocks){
      if (!block.plantings) continue;
      for (var planting of block.plantings){
        const clusterGroup = planting.clusters ? planting.clusters.find((cg)=>cg.sk === selectedClusterGroup) : false;
        const hasCluster = clusterGroup ? clusterGroup.items.find((ci) => ci === selectedCluster) : false;
        if (selectedClusterGroup !== '' && selectedCluster !== '' && !hasCluster){
          continue;
        };
        const label = `Block ${block.number} ${this.nameLookup(crops,planting.crop)} - ${this.varietyLookup(crops,planting.crop,planting.variety)}`;
        selectedBlocks.push({blockOB:block, plantingOB:planting, block:block.sk,planting:planting.sk,label:label,record:{}})

    }

  }
  newAllSelected = true;
}
  
  this.setState({allSelected:newAllSelected,selectedBlocks:selectedBlocks,changed: true});
}
  
  handleExpandActivity = (activity) => {
    let selectedActivity = this.state.selectedActivity;
    if (selectedActivity && selectedActivity.type === selectedActivity.type){
      this.setState({selectedActivity:null});
    }
    else {
      this.setState({selectedActivity: activity});
    }
  
  
  }

   handleChange = (event) => {
    let selectedPest = {...this.state.selectedPest};
    selectedPest = event.target.value;
    this.setState({ selectedPest });
  }

  handleClickShowTableRecord = (event, planting, selectedRecordId,actionType,selectedBlockId,recordType) => {
   
   let selectedBlock = this.props.getFromGlobalState('blocks').find(block=>block.sk===selectedBlockId);
    let selectedPlanting = selectedBlock?.plantings.find(p =>p.sk === planting)
    let selectedRecord = selectedPlanting[recordType].find(record =>record.sk === selectedRecordId)
    this.setState({selectedBlock: selectedBlock, selectedPlanting: selectedPlanting, selectedBlockSK: selectedBlock?.sk, selectedPlantingSK: selectedPlanting?.sk, selectedRecord: selectedRecord,selectedRecordSK: selectedRecord?.sk});
    this.setState({openDialog: recordType, isNew: false})
  }

  handleShowDetail = (event)=> {
    let showDetail = this.state.showDetail;
    showDetail = event.target.checked;
    this.setState({showDetail,clean: !showDetail });
  }

  handleUpdateBatchRecord(dialogObject,parentSK) {
    updateBatchStateRecord(dialogObject,parentSK,this.props.getFromGlobalState,this.props.setGlobalState,this.state.selectedBlockSK,this.state.selectedPlantingSK);
  }
  handleUpdateRecord(recordIn,parentSK) {
    updateStateRecord(recordIn,parentSK,this.props.getFromGlobalState,this.props.setGlobalState,this.state.selectedBlockSK,this.state.selectedPlantingSK);
  }

  handleBlockSelection(recordIn,parentSK) {
    this.setState({blockSelector:false})
  }

  setClose() {
    
    this.setState({openDialog: '', selectedRecord: null, selectedBlock: null, selectedPlanting: null, selectedBlockSK: '', selectedPlantingSK: '', selectedRecordSK: ''})
    
  }
  closeBlockSelector() {
    
    this.setState({openDialog: '',blockSelector:false, allSelected:false})
    
  }

  handleDeleteRecord(recordIn, parentSK) {
    // see if we have the block
    const {
      getFromGlobalState, setGlobalState,
    } = this.props;
    
    const { selectedBlockSK, selectedPlantingSK } = this.state;

    const blocks = getFromGlobalState('blocks');
    const deletedBlockIndex = blocks.findIndex((i) => i.sk === selectedBlockSK);
    if (deletedBlockIndex === -1) {
      alert("impossible - we don't have this block!");
      return;
    }
    // find the planting
    const { plantings } = blocks[deletedBlockIndex];
    const updatedPlantingIndex = blocks[deletedBlockIndex].plantings.findIndex((i) => i.sk === selectedPlantingSK);
    const records = plantings[updatedPlantingIndex][recordIn.type];
    const recordsIndex = (records ? plantings[updatedPlantingIndex][recordIn.type].findIndex((i) => i.sk === recordIn.sk) : -1);

    if (recordsIndex === -1) {
      alert("impossible deleting a record that doesn't exist");
    } else {
      // see if this  record has any children
      if (recordIn.type === 'fertilisings' || recordIn.type === 'sprayings') {
        const chemInventory = getFromGlobalState('inv_chemical');
        const fertInventory = getFromGlobalState('inv_fertiliser');
        if (chemInventory) {
          const chemItems = chemInventory.filter((x) => x.sk.startsWith(recordIn.sk));
          if (chemItems.length > 0) {
            chemItems.forEach((x) => chemInventory.splice(chemInventory.indexOf(x), 1));
            setGlobalState({ inv_chemical: chemInventory });
          }
        }
        if (fertInventory) {
          const fertItems = fertInventory.filter((x) => x.sk.startsWith(recordIn.sk));
          if (fertItems.length > 0) {
            fertItems.forEach((x) => fertInventory.splice(fertInventory.indexOf(x), 1));
            setGlobalState({ inv_fertiliser: fertInventory });
          }
        }
      }
      blocks[deletedBlockIndex].plantings[updatedPlantingIndex][recordIn.type].splice(recordsIndex, 1);
      setGlobalState({ blocks: blocks });
    }
  }

  onAddRecord = (event) => {
    this.setState({ openDialog: this.state.selectedActivity.type, isNew: true,blockSelector:true, selectedBlocks:[], selectedCluster: ''});
  }
  onDownloadedPDF (event) {
    event.stopPropagation();
    this.setState({ activityPDF: null ,pdf:false,reportLoading:false, reportType: '' });
  }
  onDownloadedCSV (event) {
    event.stopPropagation();
    this.setState({ activityCSV: null ,csv:false,reportLoading:false, reportType: '' });
  }
  onReportRecordPDF (activity,event) {
    event.stopPropagation();
    this.setState({ activityPDF:activity, dateRangeDialogOpen: true,reportLoading:false, reportType: 'pdf'  });
    
    //this.setState({activityPDF:activity});
  }
  onReportRecordCSV (activity,event) {
    event.stopPropagation();
    this.setState({ activityCSV:activity, dateRangeDialogOpen: true,reportLoading:false, reportType: 'csv'  });
    
    //this.setState({activityPDF:activity});
  }
  handleCancelGeneratePDF = () => {
    this.setState({ activityPDF: null, pdf:false, dateRangeDialogOpen: false,reportLoading:false, reportType: '' });
  }
  handleCancelGenerateCSV = () => {
    this.setState({ activityCSV: null, csv:false, dateRangeDialogOpen: false,reportLoading:false, reportType: '' });
  }
  handleTogglePDF = (val) => {
    this.setState({ pdf:val });

  }

  handleToggleCSV = (val) => {
    this.setState({ csv:val });
  }

  handleToggleLoading = (val) => {
    this.setState({ reportLoading:val }, () => {
      this.handleTogglePDF(true);
    });
  
  }
  handleToggleDateRangeDialog = (dialogVal, pdfVal) =>{
    this.setState({ dateRangeDialogOpen:dialogVal }, () => {
      this.handleTogglePDF(pdfVal);
    });
  }

  handleToggleDateRangeDialogCSV = (dialogVal, pdfVal) =>{
    this.setState({ dateRangeDialogOpen:dialogVal }, () => {
      this.handleToggleCSV(pdfVal);
    });
  }

  handleGeneratePDF = () => {
   this.setState({reportLoading: true}, () => { 
     this.handleToggleDateRangeDialog(false,true);
  });
}

  handleGenerateCSV = () => {
   this.setState({reportLoading: true}, () => { 
     this.handleToggleDateRangeDialogCSV(false,true);
  });
  }
  setReportStartDate = (event) => {
    this.setState({ reportStartDate: event.target.value });
  }

  setReportEndDate = (event) => {
    this.setState({ reportEndDate: event.target.value });
  }

  getPDFLogic (pdf, activityPDF,getFromGlobalState,activity, onDownloadedPDF, classes,farm, blocks, onReportRecordPDF) {
    if (!activityPDF || activityPDF.type !== activity.type || !pdf) {
      return ( 
        <Tooltip title="Generate PDF report">
      <IconButton edge="start" className={classes.menuButton} color="primary" onClick={onReportRecordPDF} aria-label="close">
      <PDFIcon />
      </IconButton></Tooltip>)
    }
    else if(!this.state.dateRangeDialogOpen && pdf && activityPDF && activityPDF.type === activity.type) {
      return (<ReportRecordPDF  startDate={this.state.reportStartDate }  endDate={this.state.reportEndDate} getFromGlobalState={getFromGlobalState} blocks={blocks} recordTypeLabel={activity.label} recordType={activity.type} onDownloaded={onDownloadedPDF} color="secondary" className={classes.pdfButton} farm={farm}  />)
    }
  }

  getCSVLogic (csv, activityCSV,getFromGlobalState,activity, onDownloadedCSV, classes,farm, blocks, onReportRecordCSV) {
        if (!activityCSV || activityCSV.type !== activity.type || !csv) {
          return (
           
          <IconButton edge="start" className={classes.menuButton} color="primary" onClick={onReportRecordCSV} aria-label="close">
             <Tooltip title="Generate CSV report">
          <DownloadIcon /></Tooltip>
          </IconButton>  )
        }
        else if(!this.state.dateRangeDialogOpen && csv && activityCSV && activityCSV.type === activity.type) {
          return (<ReportRecordCSV  startDate={this.state.reportStartDate }  endDate={this.state.reportEndDate} getFromGlobalState={getFromGlobalState} blocks={blocks} recordTypeLabel={activity.label} recordType={activity.type} onDownloaded={onDownloadedCSV} color="secondary" className={classes.pdfButton} farm={farm}  />)
        }
      }

    getReportIcons(csv,pdf, activityCSV,activityPDF,getFromGlobalState,activity, onDownloadedPDF, onDownloadedCSV, classes,farm, blocks, onReportRecordCSV,onReportRecordPDF) {
      return (
        <div style={{display:'flex', flexDirection: 'row'}}>
        {this.getCSVLogic(csv, activityCSV,getFromGlobalState,activity, onDownloadedCSV, classes,farm, blocks, onReportRecordCSV)}
        {this.getPDFLogic(pdf, activityPDF,getFromGlobalState,activity, onDownloadedPDF, classes,farm, blocks, onReportRecordPDF)}
        </div>
      )
    }

  
  renderActivities(blocks, farm, getFromGlobalState, pdfRequested, classes, userDetail) { 
    return activities.map(
      (activity) =>
      <Can
          role={userDetail && userDetail.rights && userDetail.rights.allowedActivities ? 'allowedActivities' : 'noAccess'}
          isAdministrator={!!(userDetail && userDetail?.rights && userDetail.rights.administrator)}
          perform={userDetail?.rights && userDetail?.rights?.allowedActivities && userDetail.rights.allowedActivities.includes(activity.type) ? activity.type : 'noAccess'}
          yes={() => (
          <Grid key={activity.type} style={{padding:"3px"}} item xs={12} sm={12} md={12} lg={12} xl={12}>
          <SimpleCard
           expanded = {this.state.selectedActivity && this.state.selectedActivity.type === activity.type ? true: undefined}
          disableContentPadding
          title={activity.label}
          ariaLabel={activity.label} 
          action = {
          userDetail && userDetail.rights && userDetail.rights.viewDashboard || userDetail.rights.administrator ? this.getReportIcons(this.state.csv,this.state.pdf,this.state.activityCSV,this.state.activityPDF,getFromGlobalState, activity,this.onDownloadedPDF.bind(this), this.onDownloadedCSV.bind(this), classes,farm,blocks, this.onReportRecordCSV.bind(this,activity),this.onReportRecordPDF.bind(this,activity)) : null}
           avatar={<div className={classes.iconContainer}><IconButton className={classes.button}  style={{border:this.state.selectedActivity && this.state.selectedActivity.type===activity.type? '3px solid #63c7c8':undefined}}  size='large'>{activity.icon()}</IconButton></div> }
           handleExpandClick={this.handleExpandActivity.bind(this,activity)}
           expandedContent={ 
            this.state.selectedActivity && this.state.selectedActivity.type && this.state.selectedActivity.type === activity.type && 
            <ReportByActivity
                displayToolbar
                noPagination={false}
                dense
                showAll
                recordType={this.state.selectedActivity.type}
                blocks={blocks}
                type={this.state.selectedActivity.type}
                handleSelect={this.handleClickShowTableRecord}
                getFromGlobalState={this.props.getFromGlobalState}
                handleAddItem={this.onAddRecord} 
                clean={this.state.clean}
                handleShowDetail={this.handleShowDetail}
                showDetail={this.state.showDetail}
            />
           }
              >
                
          </SimpleCard>
          </Grid>
          )}
          no={() => null}
        />
      
    );
  }

  extractClusters (blocks) {
    let clusters = [];
    if (! blocks) {
      return clusters;
    }
    const len = blocks.length;
    let i = 0;
    while (i < len) {
      if (blocks[i].plantings.length) {
      let p = 0;
      const plantings = blocks[i].plantings.length;
      while(p < plantings){
        let cluster = blocks[i].plantings[p].cluster;
        if (cluster) {
        const idx = clusters.findIndex( (p) => p === cluster);
        if (idx === -1){
          clusters.push(blocks[i].plantings[p].cluster);
        }
      }
        p += 1;
      }
    }

      i += 1;
    }
    clusters = clusters && clusters.sort((a, b) => ("" + a.number).localeCompare(b.number, undefined, {numeric: true}));
    return clusters;
  }

  filterByCluster(blocks,selectedClusterGroup, selectedCluster ) {
    let newBlocks = [];
    if (! blocks) {
      return newBlocks;
    }
    if (selectedClusterGroup === '' || selectedCluster === '') {
      return blocks;
    }
    const len = blocks.length;
    let i = 0;
    while (i < len) {
      if (blocks[i].plantings && blocks[i].plantings.length) {
      let p = 0;
      const plantings = blocks[i].plantings.length;
      while(p < plantings){
        let found = false;
        let clusters = blocks[i].plantings[p].clusters ? blocks[i].plantings[p].clusters : [];
        const clusterP = clusters.find((cp) => cp.sk === selectedClusterGroup);
        if (clusterP) {
          const clusterI = clusterP.items.find((ci) => ci === selectedCluster);
          if (clusterI){
            found = true;
          }
        }
        if ( found) {
        let foundBlock = newBlocks.find( (p) => p.sk === blocks[i].sk);
        if (!foundBlock){
          foundBlock = { ...blocks[i]};
          foundBlock.plantings=[];
          newBlocks.push(foundBlock)
        }
          const pl = {...blocks[i].plantings[p]};
          foundBlock.plantings.push(pl);
        }
        p += 1;
      }
       
      }
      i += 1;
    }
   // clusters = clusters && clusters.sort((a, b) => ("" + a.number).localeCompare(b.number, undefined, {numeric: true}));
    return newBlocks;

  }

  renderBlockSelector(getFromGlobalState) {
    let stateBlocks = getFromGlobalState('blocks');
    if ( this.state.selectedActivity.type === "waterings"){
      stateBlocks = stateBlocks.filter((c) => c.irrigated === 'irrigated') 
    }
    //const clusters = this.extractClusters(stateBlocks);
    const clusters = getFromGlobalState('clusters');
    let blocks = this.filterByCluster(stateBlocks,this.state.selectedClusterGroup, this.state.selectedCluster);
    blocks = blocks && blocks.sort((a, b) => ("" + a.number).localeCompare(b.number, undefined, {numeric: true}));
   
    
    return (<GenDialog
    open={this.state.blockSelector}
    dialogObject={undefined}
    setClose={this.closeBlockSelector.bind(this)} 
    isNew={this.state.isNew}
    handleUpdateDialogObject={this.handleBlockSelection.bind(this)}
    //skPrimary={'recordType'}
    skPrimary={''}
    dynamoType={undefined}
    valid={this.state.selectedBlocks.length > 0}
    changed={this.state.changed}
    getFromGlobalState={this.props.getFromGlobalState}
    newTitle={'Select plantings'}
    title={'Select plantings'}
   // subTitle={`Block ${block.number} v.${record.version ? record.version: '0' }`}
   subTitle={``}
    parentKey={''}
    plantingKey={''}
    noServerInteraction
    allowDelete = {false}
    handleDeleteDialogObject={undefined}
    >
      
      {this.state.blockSelector &&  
       <BlockSelector 
       record={undefined}
       errors={undefined}
       clusters={clusters}
       handleClusterChange={this.handleClusterChange}
       handleClusterGroupChange={this.handleClusterGroupChange}
       selectedCluster={this.state.selectedCluster}
       selectedClusterGroup={this.state.selectedClusterGroup}
       //handleChange={this.handleChange.bind(this)}
       handleToggle={this.handleToggle.bind(this)}
       //handleBlur={this.handleBlur.bind(this)}
       metaData={{crops:getFromGlobalState('crops')}}
      // handleComplexChange={this.handleComplexChange.bind(this)}
       //updateParent={this.updateParent.bind(this)}
       blocks = {blocks}
      // planting={block.plantings.filter(p=>p.sk===plantingSK)}
       //handleSwitchChange={this.handleSwitchChange}
       selectedBlocks = {this.state.selectedBlocks}
       onSelectAll = {this.onSelectAll}
       allSelected = {this.state.allSelected}
       />}

      
      

    </GenDialog>)
  }

  render() {
    const {classes,getFromGlobalState} = this.props;
    const { reportType } = this.state;
    const userDetail = getFromGlobalState('userDetail');
    
    return (
      <Paper elevation={0} className={classes.paper}>
      <Grid container className={classes.root} spacing={2} alignItems="flex-start"> 
      <Grid className={classes.searchBar} key='search' style={{padding:"3px",marginBottom:'3px'}} item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
      </Grid>
      <Load isloading={this.state.isLoading}>
      <Grid container className={classes.root2} spacing={2}  alignItems="flex-start"> 
        { this.renderActivities(this.props.getFromGlobalState('blocks'), this.props.getFromGlobalState('farm')[0],this.props.getFromGlobalState, this.state.pdf,classes, userDetail)}

        {(this.state.selectedActivity && this.state.selectedActivity.type && this.state.openDialog !== "" && !this.state.isNew) && 
          <RecordD
          handleUpdateRecord={this.handleUpdateRecord.bind(this)} 
          handleDeleteRecord={this.handleDeleteRecord.bind(this)}
          allowDelete
          open={this.state.openDialog === this.state.selectedActivity.type} 
          setClose={this.setClose.bind(this)} 
          isNew={this.state.isNew} 
          record={this.state.selectedRecord}
          blockSK={this.state.selectedBlockSK}
          block={this.state.selectedBlock}
          plantingSK={this.state.selectedPlantingSK}
          planting={this.state.selectedPlanting} 
          recordType={this.state.selectedActivity.type}
          newTitle = {` New ${activities.find(e => e.type === this.state.selectedActivity.type).label}`}
          title = {activities.find(e => e.type === this.state.selectedActivity.type).label}
          getFromGlobalState={this.props.getFromGlobalState}
          requiredFields={activities.find(e=>e.type === this.state.selectedActivity.type).requiredFields}
          />}
           {(!this.state.blockSelector && this.state.selectedActivity && this.state.selectedActivity.type && this.state.openDialog !== "" && this.state.isNew) && 
          <RecordDBatch
          handleUpdateRecord={this.handleUpdateBatchRecord.bind(this)} 
          handleDeleteRecord={this.handleDeleteRecord.bind(this)}
          allowDelete
  
          open={this.state.openDialog === this.state.selectedActivity.type} 
          setClose={this.setClose.bind(this)} 
          isNew={this.state.isNew} 
          record={this.state.selectedRecord}
          selectedBlocks={this.state.selectedBlocks}
          blockSK={this.state.selectedBlockSK}
          plantingSK={this.state.selectedPlantingSK}
          planting={this.state.selectedPlanting} 
          recordType={this.state.selectedActivity.type}
          newTitle = {` New ${activities.find(e => e.type === this.state.selectedActivity.type).label}`}
          title = {activities.find(e => e.type === this.state.selectedActivity.type).label}
          getFromGlobalState={this.props.getFromGlobalState}
          
          requiredFields={activities.find(e=>e.type === this.state.selectedActivity.type).requiredFields}
          />}
          {this.state.blockSelector && this.renderBlockSelector(getFromGlobalState)}

          {this.state.dateRangeDialogOpen
        && (
        <DateRangeDialog
          open={this.state.dateRangeDialogOpen}
          loading={this.state.reportLoading}
          title="Report Date Range"
          handleConfirm={reportType === 'pdf'? this.handleGeneratePDF :  this.handleGenerateCSV}
          handleCancel={reportType === 'csv' ? this.handleCancelGeneratePDF : this.handleCancelGenerateCSV}
          startDate={this.state.reportStartDate}
          endDate={this.state.reportEndDate}
          handleSetStartDate={this.setReportStartDate}
          handleSetEndDate={this.setReportEndDate}
        />
        )}
    </Grid>
    </Load> 
    </Paper>
    );
  }
}
Records.propTypes = {
  pdfRequested: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Records);
