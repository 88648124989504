import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import GeoIcon from '@material-ui/icons/GpsFixedOutlined';
import LoaderFab from '../../components/LoaderFab';
import countryData from '../../data/countries.json';
import Map from '../../components/Map';
import { geoStringToLatLong } from '../../libs/genLib';
import InfoBox from '../../components/InfoBox';
import FileUploader from '../FileUploader';
import { getDownloadUrl } from '../../libs/storage';
import { validate, validateForm } from '../../libs/validate';
import GenDialog from '../../components/GenDialog';
import { Switch } from '@material-ui/core';


const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  formControlRadio: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },
});

const requiredFields = [
  { name: 'name', min: 1, max: 250 },
  { name: 'entityType', min: 2, max: 100 },
  { name: 'shortName', min: 1, max: 25 },
  { name: 'address1', min: 1, max: 100 },
  { name: 'address2', min: 1, max: 100 },
  { name: 'country', min: 1, max: 100 },
  { name: 'province', min: 1, max: 100 },
  { name: 'pCode', min: 1, max: 40 },
  { name: 'contactNum', min: 1, max: 40 },
  { name: 'email', min: 1, max: 40 },
];

class FarmD extends Component {
  constructor(props) {
    super(props);
    const { farm, handleUpdate } = this.props;
    this.handleUpdate = handleUpdate.bind(this);
    this.onMarkerComplete = this.onMarkerComplete.bind(this);
    this.mapFileUploaded = this.mapFileUploaded.bind(this);
    this.fileUploaded = this.fileUploaded.bind(this);
    this.state = {
      farm,
      errors: { count: 0 },
      changed: false,
      locationLoading: false,
      // locationLoaded: true,
      regions: [],
      logoChanged: false,
      logoURL: null,
      copyEntity:false,
      untouched:{}
    };
  }

  async componentDidMount() {
    const { isNew } = this.props;
    const { farm } = this.state;

    if (isNew) {
      this.setState({ farm: { entityType: 'farm' } });
    } else {
      const newFarm = { ...farm };
      // let logoURL='';
      if (farm.country) {
        const countryFilter = countryData.filter((p) => p.countryName === farm.country);
        const regions = countryFilter.length > 0 ? countryFilter[0].regions : [];
        
        this.setState({ farm: newFarm, regions });
      }
      if (farm.logoKey) {
        const logoURL = await this.getLogoUrl(farm.logoKey, farm.logoFileType);
        const farmMapKml = await this.getLogoUrl(farm.farmKml, farm.farmKmlFileType);
        this.setState({ logoURL, farmMapKml });
      }
      if (!newFarm.entityType) {
        newFarm.entityType = 'farm';
        this.setState({ farm: newFarm });
      }
    }
  }

  handleBlur = (event) => this.validateField(event.target.id)

  fileUploaded = async (file) => {
    if (!file.serverId) {
      console.log('received undefined key');
      return;
    }
    const { farm } = this.state;
    const newFarm = { ...farm };
    newFarm.logoKey = file.serverId;
    newFarm.logoFileType = file.fileType;
    const logoURL = await this.getLogoUrl(newFarm.logoKey, newFarm.logoFileType);
    this.setState({ logoURL, changed: true, farm: newFarm });
  }

  mapFileUploaded = async (file) => {
    if (!file.serverId) {
      console.log('received undefined key');
      return;
    }
    const { farm } = this.state;
    const newFarm = { ...farm };
    newFarm.farmKml = file.serverId;
    newFarm.farmKmlFileType = file.fileType;
    const farmMapKml = await this.getLogoUrl(farm.farmKml, farm.farmKmlFileType);
    this.setState({ farmMapKml, changed: true, farm: newFarm });
  }

  getLogoUrl = async (key, fileType) => {
    const { getFromGlobalState } = this.props;
    const params = {
      activeTenant: getFromGlobalState('activeSite'),
      type: 'files',
      path: key,
      contentType: fileType,
    };
    return getDownloadUrl(params);
  }

  handleLatGeoChange = (event) => {
    const { farm } = this.state;
    const newFarm = { ...farm };
    if (!newFarm.geoLocation) {
      newFarm.geoLocation = { lat: null, lng: null };
    }
    newFarm.geoLocation.lat = parseFloat(event.target.value);
    if (isNaN(newFarm.geoLocation.lat)) {
      newFarm.geoLocation.lat = null;
    }
    this.setState({ farm: newFarm, changed: true });
  }

  handleLngGeoChange = (event) => {
    const { farm } = this.state;
    const newFarm = { ...farm };
    if (!newFarm.geoLocation) {
      newFarm.geoLocation = { lat: null, lng: null };
    }
    newFarm.geoLocation.lng = parseFloat(event.target.value);
    if (isNaN(newFarm.geoLocation.lng)) {
      newFarm.geoLocation.lng = null;
    }
    this.setState({ farm: newFarm, changed: true });
  }

  getCurrentLocation = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 100000,
      maximumAge: 0,
    };
    this.setState({ locationLoading: true /* ,locationLoaded: false */ });
    navigator.geolocation.getCurrentPosition((position) => {
      const location = { lat: position.coords.latitude, lng: position.coords.longitude, accuracy: position.coords.accuracy, altitude: position.coords.altitude, altAcc: position.coords.altitudeAccuracy };
      const { farm } = this.state;
      const newFarm = { ...farm };
      newFarm.geoLocation = location;
      this.setState({
        farm: newFarm, changed: true, locationLoading: false, /* , locationLoaded: true */
      });
    },
    (error) => {
     // if (error.code === error.PERMISSION_DENIED) {
        this.setState({ locationLoading: false /* locationLoaded: false */ });
      //}
      alert(error.message);
    },
    options);
  }

  handleChange = (event) => {
    const { farm } = this.state;
    const newFarm = { ...farm };
    newFarm[event.target.id] = event.target.value;
    this.setState({ farm: newFarm, changed: true });
  }

  handleRadioChange = (event, id) => {
    const { farm } = this.state;
    const newFarm = { ...farm };
    newFarm[id] = event.target.value;
    this.setState({ farm: newFarm, changed: true });
  }

  handleCountryChange = (event) => {
    const { farm } = this.state;
    const newFarm = { ...farm };
    if (newFarm.country !== event.target.value && newFarm.province) {
      newFarm.province = '';
    }
    newFarm[event.target.id] = event.target.value;
    const countryFilter = countryData.filter((p) => p.countryName === event.target.value);
    const regions = countryFilter.length > 0 ? countryFilter[0].regions : [];
    this.setState({ farm: newFarm, changed: true, regions });
  }

  onMarkerComplete = (event) => {
    const { farm } = this.state;
    const newFarm = { ...farm };
    newFarm.geoLocation = { lat: event.position.lat(), lng: event.position.lng() };
    this.setState({ farm: newFarm, changed: true });
  }

  validateField(field) {
    const { farm, errors } = this.state;
    const error = validate(field, farm, requiredFields);
    const newErrors = { ...errors };
    errors[field] = error.msg;
    this.setState({ errors: newErrors });
  }

  render() {
    const {
      classes, open, isNew, setClose, getFromGlobalState,
    } = this.props;
    const {
      farm,
      errors,
      regions,
      farmMapKml,
      isUploadingFile,
      logoChanged,
      changed,
      locationLoading,
      logoURL,
    } = this.state;
    const formValid = validateForm(farm, requiredFields);
    let zoom = 16;
    if (!farm.geoLocation) {
      zoom = 2;
    }
    const center = farm.geoLocation && farm.geoLocation.lat && farm.geoLocation.lng ? geoStringToLatLong(farm.geoLocation) : { lat: -30.664413, lng: 23.758869 };
    const markers = farm.geoLocation ? [{ position: center, sk: farm.key, clickable: true }] : [];
    const prefix = farm.entityType === 'farm' ? 'Farm' : 'Entity';
    let siteList = getFromGlobalState('siteList');
    const ogFarm = siteList.find((x)=>x.tenantId === farm.farmId);
    return (
      <GenDialog
        open={open}
        dialogObject={farm}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={this.handleUpdate}
        skPrimary="init_setting_"
        dynamoType="farm"
        valid={!isUploadingFile && formValid && !logoChanged}
        changed={changed}
        newTitle="Farm Details"
        title={farm.name}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-end">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl component="fieldset" className={classes.formControlRadio}>
              <FormLabel shrink component="legend" required>Entity or farm</FormLabel>
              <RadioGroup
                aria-label="entityType"
                name="entityType"
                id="entityType"
                className={classes.group}
                value={farm ? farm.entityType : ""}
                onChange={(e) => this.handleRadioChange(e, 'entityType')}
              >
                <FormControlLabel value="entity" control={<Radio />} label="Entity" />
                <FormControlLabel value="farm" control={<Radio />} label="Farm" />
                {errors.entityType
                && <FormHelperText className={classes.helperText}>{errors.entityType}</FormHelperText>}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <InfoBox message={ farm.entityType === 'farm' ? 'Find the center of the farm on the map and place a pin or select find my location if you are on the farm. You can also enter the latitude and longitude if you have them.'
              : 'Find the physical location of the entity office on the map and place a pin or select find my location if you are at the entity office. You can also enter the latitude and longitude if you have them'}
            />
          </Grid>
          { farm.entityType === 'farm' && <Grid style={{marginTop:10}} item xs={11} sm={11} md={11} lg={11} xl={11}>
          <FormLabel shrink component="legend" required>Copy Farm Details From Entity {ogFarm?.parentFarm?.name || ogFarm?.parentFarm?.shortName}</FormLabel>
            <Switch
            checked={this.state.copyEntity}
            name="copyEntity"
            InputLabelProps={{
              shrink: true,
            }}
            value={this.state.copyEntity}
            onChange={(x)=>{
              if(!this.state.copyEntity){
                const countryFilter = countryData.filter((p) => p.countryName === ogFarm.parentFarm.country);
                console.log(countryFilter);
                const regions = countryFilter.length > 0 ? countryFilter[0].regions : [];
                this.setState({untouched:farm,farm:{...farm,
                  geoLocation:ogFarm?.parentFarm?.geoLocation,
                  geoLocationLat:ogFarm?.parentFarm?.geoLocationLat,
                  geoLocationLn:ogFarm?.parentFarm?.geoLocationLn,
                  puc:ogFarm?.parentFarm?.puc,
                  gapnum:ogFarm?.parentFarm?.gapnum,
                  vatNo:ogFarm?.parentFarm?.vatNo,
                  address1:ogFarm?.parentFarm?.address1,
                  address2:ogFarm?.parentFarm?.address2,
                  country:ogFarm?.parentFarm?.country,
                  province:ogFarm?.parentFarm?.province,
                  pCode:ogFarm?.parentFarm?.pCode,
                  contactNum:ogFarm?.parentFarm?.contactNum,
                  email:ogFarm?.parentFarm?.email
                  },regions})
                if (ogFarm?.parentFarm?.logoKey) {
                    this.getLogoUrl(ogFarm?.parentFarm?.logoKey,ogFarm?.parentFarm?.logoFileType);
                }
              }else{
                this.setState({farm:this.state.untouched})
              }
              this.setState({copyEntity:!this.state.copyEntity})
            }}
            />
          </Grid>}
          <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
            <TextField
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: (!!farm.geoLocation),
              }}
              id="geoLocation"
              label="Find my location"
              name="geoLocation"
              disabled
              value={ farm.geoLocation ? (farm.geoLocation.lat && farm.geoLocation.lng ? `${farm.geoLocation.lat.toFixed(7)} ${farm.geoLocation.lng.toFixed(7)}` : '') : '' }
              onChange={this.handleGeoChange}
              onBlur={this.handleBlur}
              error={errors.geoLocation ? true : undefined}
              helperText={errors.geoLocation}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <LoaderFab
              onClick={this.getCurrentLocation}
              success 
              icon={<GeoIcon />}
              loading={locationLoading}
            />
          </Grid>
           <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
            margin="normal"
            fullWidth
            type="number"
            InputLabelProps={{
              shrink : (farm.geoLocation? true : false),
            }}
            id="geoLocationLat"
            label="Manual Entry Lat"
            name="geoLocationLat"
            value={ farm.geoLocation && farm.geoLocation.lat ? farm.geoLocation.lat: ''}
            onChange={this.handleLatGeoChange}
            onBlur={this.handleBlur}
            error={errors.geoLocation? true : undefined}
            helperText={errors.geoLocation}
          />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
            margin="normal"
            fullWidth
            type="number"
            InputLabelProps={{
              shrink : (farm.geoLocation? true : false),
            }}
            id="geoLocationLng"
            label="Manual Entry Lng"
            name="geoLocationLng"
            value={ farm.geoLocation && farm.geoLocation.lng ? farm.geoLocation.lng: ''}
            onChange={this.handleLngGeoChange}
            onBlur={this.handleBlur}
            error={errors.geoLocation? true : undefined}
            helperText={errors.geoLocation}
          />
          </Grid> 
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Map
              center={center}
              zoom={zoom}
              markers={markers}
              mode="marker"
              kmlLayerSrc={farmMapKml}
              onMarkerComplete={this.onMarkerComplete}
            />
          </Grid>
         
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label={`${prefix} Name`}
              name="name"
              InputLabelProps={{
                shrink: true,
              }}
              value={farm.name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="shortName"
              label={`${prefix} Short Name`}
              name="shortName"
              value={farm.shortName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.shortName ? true : undefined}
              helperText={errors.shortName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              fullWidth
              id="puc"
              label="Production Unit Code (PUC)"
              name="puc"
              value={farm.puc}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.puc ? true : undefined}
              helperText={errors.puc}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
            // required
              fullWidth
              id="gapnum"
              label="GLOBALG.A.P. Number"
              name="gapnum"
              InputLabelProps={{
                shrink: true,
              }}
              value={farm.gapnum}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.gapnum ? true : undefined}
              helperText={errors.gapnum}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
            // required
              fullWidth
              id="vatNo"
              label="VAT number"
              name="vatNo"
              InputLabelProps={{
                shrink: true,
              }}
              value={farm.vatNo}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.vatNo ? true : undefined}
              helperText={errors.vatNo}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="address1"
              label={`${prefix} Physical Address Line 1`}
              name="address1"
              InputLabelProps={{
                shrink: true,
              }}
              value={farm.address1}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.address1 ? true : undefined}
              helperText={errors.address1}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="address2"
              label={`${prefix} Physical Address Line 2`}
              name="address2"
              value={farm.address2}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.address2 ? true : undefined}
              helperText={errors.address2}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor="country">Country</InputLabel>
              <Select
                native
                fullWidth
                margin="normal"
                required
                displayEmpty
                value={farm.country}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleCountryChange}
                onBlur={this.handleBlur}
                error={errors.country ? true : undefined}
                inputProps={{
                  name: 'country',
                  id: 'country',
                }}
              >
                <option aria-label="empty" value="" />
                {countryData && countryData.map((country) => (
                  <>
                    <option value={country.countryName}>{country.countryName}</option>
                  </>
                ))}
              </Select>
              {errors.country
              && <FormHelperText className={classes.helperText}>{errors.country}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor="Province">Province</InputLabel>
              <Select
                native
                fullWidth
                required
                displayEmpty
                margin="normal"
                value={farm.province}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={(!farm.province && regions.length < 1)}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={errors.province ? true : undefined}
                inputProps={{
                  name: 'province',
                  id: 'province',
                }}
              >
                <option aria-label="empty" value="" />
                {regions && regions.map((region) => (
                  <>
                    <option value={region.name}>{region.name}</option>
                  </>
                ))}
              </Select>
              {errors.province
              && <FormHelperText className={classes.helperText}>{errors.province}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              id="pCode"
              label="Postal Code"
              InputLabelProps={{
                shrink: true,
              }}
              name="pCode"
              value={farm.pCode}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.pCode ? true : undefined}
              helperText={errors.pCode}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="contactNum"
              label={`${prefix} Contact Number`}
              name="contactNum"
              value={farm.contactNum}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.contactNum ? true : undefined}
              helperText={errors.contactNum}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={`${prefix} Email`}
              name="email"
              value={farm.email}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.email ? true : undefined}
              helperText={errors.email}
            />
          </Grid>
          <InputLabel shrink htmlFor="logo">{`${prefix} Logo`}</InputLabel>
          {farm.logoKey && farm.logoKey && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div>
              <br />
              <img alt="Logo for the farm" id="logo" src={logoURL} style={{ maxWidth: '150px' }} />
            </div>
          </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FileUploader
              activeTenant={getFromGlobalState('activeSite')}
              generateThumbnail={false}
              generatePreview={false}
              maxFileSize="350KB"
              onFileUploaded={this.fileUploaded}
              folder="images/logo/"
              getFromGlobalState={getFromGlobalState}
              allowMultiple={false}
              forceName
              fileName="farmLogo"
              allowFileTypeValidation
              acceptedFileTypes={['image/png', 'image/jpeg', 'image/gif', 'image/svg+xml', 'image/webp', 'image/x-icon']}
            />
          </Grid>

        </Grid>
      </GenDialog>

    );
  }
}
FarmD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  farm: PropTypes.object.isRequired,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(FarmD);
